<template>
  <div id="FlightDelayReport" class="Content-Page fixbug">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Summary</div>
      </div>
    </div>
    <div id="filltertReport" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Filter</div>
      </div>
      <div class="box-S4">
        <div class="B-carddetail flex-between-center">
          <div class="box-S4 noPadding">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="0" :nudge-top="0"
              transition="scale-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field class="hideMessage input-date" append-icon="mdi-calendar-range" v-model="picker"
                  label="Select Date" readonly dense v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="picker" type="month" @change="Selectmonth(picker)"
                @input="menu = false"></v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
    </div>

    <div class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">CPOC</div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Total Flight</p>
              <p v-if="totalFlightCPOC != null" class="T-size-20 SemiBold">
                {{ totalFlightCPOC }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Flight Time (Hours)</p>
              <p v-if="totalFlightTimeCPOC != null" class="T-size-20 SemiBold">
                {{ totalFlightTimeCPOC }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Estimate (USD)</p>
              <p v-if="estimateCPOC != null" class="T-size-20 SemiBold">
                {{ estimateCPOC }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">CHOC</div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Total Flight</p>
              <p v-if="totalFlightCHOC != null" class="T-size-20 SemiBold">
                {{ totalFlightCHOC }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Flight Time (Hours)</p>
              <p v-if="totalFlightTimeCHOC != null" class="T-size-20 SemiBold">
                {{ totalFlightTimeCHOC }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Estimate (USD)</p>
              <p v-if="estimateCHOC != null" class="T-size-20 SemiBold">
                {{ estimateCHOC }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">MUBADALA</div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Total Flight</p>
              <p v-if="totalFlightMUBADALA != null" class="T-size-20 SemiBold">
                {{ totalFlightMUBADALA }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Flight Time (Hours)</p>
              <p v-if="totalFlightTimeMUBADALA != null" class="T-size-20 SemiBold">
                {{ totalFlightTimeMUBADALA }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Estimate (USD)</p>
              <p v-if="estimateMUBADALA != null" class="T-size-20 SemiBold">
                {{ estimateMUBADALA }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">PTT</div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Total Flight</p>
              <p v-if="totalFlightPTT != null" class="T-size-20 SemiBold">
                {{ totalFlightPTT }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Flight Time (Hours)</p>
              <p v-if="totalFlightTimePTT != null" class="T-size-20 SemiBold">
                {{ totalFlightTimePTT }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1-3">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-text">
              <p>Estimate (THB)</p>
              <p v-if="estimatePTT != null" class="T-size-20 SemiBold">
                {{ estimatePTT }}
              </p>
              <p v-else class="T-size-20 SemiBold">
                0
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate,
} from "date-fns";
import moment from "moment";
import { faIR } from "date-fns/locale";

export default {
  data: () => ({
    menu: null,
    total: 0,
    loading: false,
    dialog: false,
    formModel: {},
    type: "month",
    picker: {},
    dateSelect: new Date().toISOString().substr(0, 7),
    //Add
    userdata: null,

    totalFlightCPOC: null,
    totalFlightTimeCPOC: null,
    estimateCPOC: null,

    totalFlightCHOC: null,
    totalFlightTimeCHOC: null,
    estimateCHOC: null,

    totalFlightMUBADALA: null,
    totalFlightTimeMUBADALA: null,
    estimateMUBADALA: null,

    totalFlightPTT: null,
    totalFlightTimePTT: null,
    estimatePTT: null,
  }),
  computed: {},
  async mounted() {
    //Add
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.picker = this.dateSelect;
    this.Getsummary();
  },

  methods: {
    async Selectmonth(picker) {
      this.picker = picker;
      await this.Getsummary();
    },

    async Getsummary() {
      this.totalFlightCPOC = null;
      this.totalFlightTimeCPOC = null;
      this.estimateCPOC = null;

      this.totalFlightCHOC = null;
      this.totalFlightTimeCHOC = null;
      this.estimateCHOC = null;

      this.totalFlightMUBADALA = null;
      this.totalFlightTimeMUBADALA = null;
      this.estimateMUBADALA = null;

      this.totalFlightPTT = null;
      this.totalFlightTimePTT = null;
      this.estimatePTT = null;

      const q = {};
      q.month = this.picker.substr(5, 2);
      q.year = this.picker.substr(0, 4);
      var res = await feathersClientUOA
        .service("summary")
        .find({ query: q });

      res.forEach(element => {
        if (element.o_id == 2) {
          this.totalFlightCPOC = element.totalflight;
          this.totalFlightTimeCPOC = element.revenue;
          this.estimateCPOC = 0;
        }
        if (element.o_id == 3) {
          this.totalFlightCHOC = element.totalflight;
          this.totalFlightTimeCHOC = element.revenue;
          this.estimateCHOC = 0;
        }
        if (element.o_id == 4) {
          this.totalFlightMUBADALA = element.totalflight;
          this.totalFlightTimeMUBADALA = element.revenue;
          this.estimateMUBADALA = 0;
        }
        if (element.o_id == 25) {
          this.totalFlightPTT = element.totalflight;
          this.totalFlightTimePTT = element.revenue;
          this.estimatePTT = 0;
        }
      });
    },

    convertMinsToHrsMins: function (minutes) {
      var h = Math.floor(minutes / 60);
      var m = minutes % 60;
      h = h < 10 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;
      return h + ':' + m;
    },

    commaSeparateNumber: function (val) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
      }
      return val;
    }
  },
};


</script> 


<style>

</style>